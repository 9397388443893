.payment-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.header-container input {
    width: 100%;
    max-width: 250px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.header-container button {
    background-color: #1890ff;
    color: #fff;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
}

.header-container button:hover {
    background-color: #40a9ff;
}

.ant-table {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

.ant-pagination {
    margin-top: 20px;
    text-align: center;
}

@media (max-width: 768px) {
    .payment-container {
        padding: 15px;
    }
    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .header-container input, .header-container button {
        width: 100%;
        max-width: none;
    }
    .ant-pagination {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .payment-container {
        padding: 10px;
    }
    .header-container input, .header-container button {
        font-size: 14px;
        padding: 6px;
    }
}
/* Main Container */
.payment-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header Container */
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  
  /* Input Fields */
  .ant-input {
    width: 250px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    padding: 8px 12px;
  }
  
  /* Select Dropdown */
  .ant-select {
    width: 200px;
  }
  
  /* Download Button */
  .ant-btn {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
  }
  
  .ant-btn:hover {
    background-color: #0056b3;
  }
  
  /* Table Styling */
  .ant-table {
    background: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  /* Pagination */
  .ant-pagination {
    text-align: center;
    margin-top: 20px;
  }
  