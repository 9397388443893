.job-list-container {
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  background: var(--bg-light);
  display: flex;
  justify-content: center;
}

.job-list {
  width: 90%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
}

/* Job Card */
.job-card {
  background: var(--bg-white);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.job-card h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #0f4c81; /* Darker shade of blue */
  text-align: left;
}

.job-card p {
  margin: 5px 0;
  color: #555;
  font-size: 0.95rem;
  line-height: 1.4;
}

.expired-label {
  color: #dc3545;
  font-weight: bold;
}

/* Job Description */
.job-description {
  margin: 10px 0;
  font-size: 0.95rem;
  color: #444;
  line-height: 1.5;
  background: #f3faff; /* Slightly tinted background */
  padding: 10px;
  border-radius: 4px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 60px; /* collapsed state */
}

/* Expanded description */
.job-description.expanded {
  max-height: 1000px; /* something large to reveal entire content */
}

/* Toggle Link */
.toggle-description {
  color: var(--secondary-color);
  cursor: pointer;
  font-size: 0.9rem;
  margin-bottom: 10px;
  display: inline-block;
  transition: color 0.2s ease;
}

.toggle-description:hover {
  color: var(--accent-color);
}

/* Apply Button */
.apply-button {
  align-self: center;
  background-color: #28a745; /* Green */
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: auto;
}

.apply-button:hover:enabled {
  background-color: #218838;
  transform: scale(1.03);
}

.apply-button:disabled {
  background-color: #dc3545; /* Red for expired jobs */
  cursor: not-allowed;
}

/* Expired Card (optional subtle style) */
.job-card.expired {
  opacity: 0.8;
  background: linear-gradient(
    135deg,
    rgba(255, 244, 244, 0.95),
    rgba(255, 239, 239, 0.95)
  );
}

/* Responsive */
@media (max-width: 768px) {
  .job-list {
    grid-template-columns: 1fr;
  }

  .job-card {
    padding: 15px;
  }

  .job-card h3 {
    font-size: 1.2rem;
  }

  .job-card p,
  .toggle-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .job-card {
    padding: 15px;
  }

  .apply-button {
    width: 100%;
    font-size: 0.9rem;
    padding: 10px;
  }
}
