/* Root styling */
.contact-us {
  font-family: "Roboto", sans-serif;
  background-color: #f9f9f9;
}

/* Banner */
.banner {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.banner-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}
.banner-overlay p {
  font-size: 1.2rem;
  margin: 0 20px;
  max-width: 600px;
}

/* Main Container */
.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px auto;
  padding: 0 20px;
  max-width: 1200px;
  justify-content: center;
}

/* Contact Details Section */
.contact-details,
.contact-form {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-details h2,
.contact-form h2 {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 15px;
}
.contact-details p {
  font-size: 1rem;
  margin: 10px 0;
}
.contact-details .icon {
  margin-right: 10px;
  color: #007bff;
}
.clickable {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}
.clickable:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Social Icons */
.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.social-icon {
  font-size: 1.5rem;
  color: #007bff;
  transition: transform 0.3s, color 0.3s;
}
.social-icon:hover {
  transform: scale(1.2);
  color: #0056b3;
}

/* Map */
.map-container {
  margin-top: 20px;
}
.map-container iframe {
  width: 100%;
  height: 250px;
  border: 0;
  border-radius: 10px;
}

/* Contact Form */
.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}
.contact-form textarea {
  resize: vertical;
}

/* Captcha */
.captcha-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.captcha-text {
  background: #f1f1f1;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

/* Submit Button */
.attractive-btn {
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 12px 25px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.4s;
  box-shadow: 0 5px 15px rgba(255, 117, 140, 0.3);
}
.attractive-btn:hover {
  background: linear-gradient(135deg, #ff758c, #ff7eb3);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 117, 140, 0.4);
}
.attractive-btn:active {
  transform: translateY(1px);
  box-shadow: 0 4px 10px rgba(255, 117, 140, 0.2);
}

/* Response & Errors */
.response-message {
  font-size: 1rem;
  color: green;
  margin-top: 10px;
}
.error-text {
  color: red;
  font-size: 0.9rem;
}

/* Floating Chat Button (Left side) */
.floating-chat-button {
  position: fixed;
  bottom: 75px;
  left: 25px; /* pinned to left side */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 1.4rem;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: transform 0.3s ease-in-out;
}
.floating-chat-button:hover {
  transform: scale(1.05);
}

/* Chatbot Container (also on the left) */
.chatbot-container {
  position: fixed;
  bottom: 140px;
  left: 25px;
  width: 320px;
  max-height: 480px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 10000;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

/* Chatbot Header */
.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #007bff;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 1rem;
}
.chatbot-close-btn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

/* Chat Window */
.chatbot-window {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background: #f9fafc;
}

/* Chat Messages */
.chatbot-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  font-size: 0.95rem;
}
.chatbot-serial {
  margin-right: 5px;
  font-weight: bold;
  color: #333;
}
.chatbot-avatar {
  margin-right: 8px;
  font-size: 1rem;
  color: #007bff;
}
.chatbot-text {
  background: #e9ecef;
  border-radius: 10px;
  padding: 6px 10px;
  max-width: 80%;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #333;
}
.chatbot-message.user .chatbot-text {
  background: #007bff;
  color: #fff;
}
.chatbot-typing {
  font-style: italic;
  color: #888;
  margin: 5px 0;
  font-size: 0.9rem;
}

/* Chat Input (textarea) */
.chatbot-input-container {
  display: flex;
  gap: 5px;
  padding: 8px;
  background: #f1f1f1;
}
.chatbot-input {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px;
  font-size: 0.9rem;
  outline: none;
  resize: none;
}
.chatbot-send-btn {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner {
    height: 200px;
  }
  .banner-overlay h1 {
    font-size: 1.8rem;
  }
  .banner-overlay p {
    font-size: 1rem;
  }
  .contact-container {
    flex-direction: column;
    padding: 0 15px;
  }
  .contact-details,
  .contact-form {
    margin-bottom: 20px;
  }
  /* Adjust chatbot width on smaller screens */
  .chatbot-container {
    width: 90%;
    left: 5%;
    bottom: 100px;
  }
  .floating-chat-button {
    bottom: 20px;
    left: 20px;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 150px;
  }
  .banner-overlay h1 {
    font-size: 1.5rem;
  }
  .banner-overlay p {
    font-size: 0.9rem;
  }
  .chatbot-container {
    width: 95%;
    left: 2.5%;
  }
}
