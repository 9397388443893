/* General Styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  box-sizing: border-box;
}

h2 {
  font-size: 2.5rem;
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}

/* Loader */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader span {
  width: 20px;
  height: 20px;
  margin: 5px;
  background: #fff;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.loader span:nth-child(2) {
  animation-delay: 0.3s;
}

.loader span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Banner Section */
.banner {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
}

.banner-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* About Us Section */
.about-us {
  padding: 40px 20px;
  background: #ffffff;
  text-align: center;
  animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Services Section */
/* Services Section */

.services h2 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px #00000050;
}

/* Services Grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

/* Service Card */
.service-card {
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
  padding: 15px;
  position: relative;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.service-card.zoom {
  transform: scale(1.1);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

/* Service Image */
.service-image {
  width: 100%; /* Adjust to desired size */
  border-radius: 15px;
  margin-bottom: 25px;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover .service-image {
  transform: scale(1.05);
 
}

/* Service Content */
.service-content {
  padding: 10px;
}

.service-title {
  font-size: 1.5rem;
  color: #0056b3;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.service-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

/* Animation on Card Click */
@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

/* Projects Section */
.recent-projects {
  padding: 40px 20px;
  background: #ffffff;
}

.projects-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Testimonials Section */
.testimonials {
  padding: 40px 20px;
  background: #f9f9f9;
}

.testimonial-item {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px;
}

/* Contact Section */
.contact-cta {
  padding: 40px 20px;
  background: #007bff;
  color: #fff;
  text-align: center;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background: #0056b3;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.cta-button:hover {
  background: #004494;
}

/* About Us Section */
/* About Us Section */
.about-us {
  padding: 40px 20px;
  background: linear-gradient(to bottom, #f0f4f8, #ffffff);
  text-align: center;
}

.about-us h2 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/* About Us Cards */
.about-us-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

/* Individual Card */
.about-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transform: rotateY(360deg);
  opacity: 0;
  animation: rotateIn 0.8s ease forwards;
  animation-delay: calc(var(--card-index) * 0.2s);
}

.about-card h3 {
  font-size: 1.5rem;
  color: #0056b3;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.about-card p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #333;
}

.about-card ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}

.about-card ul li {
  margin-bottom: 10px;
}

/* Rotation Animation */
@keyframes rotateIn {
  0% {
    transform: rotateY(360deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

/* Recent Projects and Testimonials Section */
/* Work and Testimonials Section */
.work-and-testimonials {
  padding: 40px 20px;
  background: #f9f9f9;
  text-align: center;
}

.work-and-testimonials h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #007bff;
}

/* Recent Projects */
.recent-projects {
  margin-bottom: 40px; /* Space between rows */
}

.recent-projects h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #0056b3;
}

.projects-container {
  display: flex;
  align-items: center;
  position: relative;
}

.projects-slider {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 20px;
  padding: 10px;
  width: 100%;
}

.project-item {
  min-width: 250px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.project-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.project-item h4 {
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 10px;
}

.project-item p {
  font-size: 1rem;
  color: #555;
}

/* Scroll Buttons */
.scroll-button {
  background: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
}

.scroll-button.left {
  left: -20px;
}

.scroll-button.right {
  right: -20px;
}

.scroll-button:hover {
  background: #0056b3;
  transform: scale(1.1);
}

/* Testimonials */
/* Testimonials Section */
.testimonials {
 
  text-align: center;
}

.testimonials h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #0056b3;
}

.testimonials-container {
  height: 250px; /* Adjust height for visible testimonial area */
  overflow: hidden;
  position: relative;
  margin-top: 20px; /* Ensure scrolling starts below the heading */
}

.testimonial-item {
  font-size: 1.2rem;
  color: #333;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  animation: scrollTestimonials 10s linear infinite; /* Adjust speed */
}

/* Keyframes for Top-to-Bottom Scrolling */
@keyframes scrollTestimonials {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

/* Ensure proper spacing between testimonials */
.testimonial-item:not(:last-child) {
  margin-bottom: 15px;
}



/* News and FAQ Section */
/* Stay Updated Section */
/* Stay Updated Section */
.stay-updated {
  display: flex;
  gap: 20px;
  padding: 40px 20px;
  background: #f9f9f9;
  justify-content: space-between;
}

/* Latest News Section - 60% */






/* Keyframes for Smooth Scrolling */
@keyframes scrollNews {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* FAQs Section - 40% */
.faqs {
  flex: 2;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.faqs h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #0056b3;
}

.faq-item {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.faq-item h4 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #007bff;
}

.faq-item p {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
}

.faq-item:hover {
  background: #f1f1f1;
  transform: scale(1.02);
}

.faq-item.expanded {
  background: #eef4ff;
}



/* Banner Section */
.banner {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 40px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Gradient overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content {
  z-index: 1;
  max-width: 800px;
  text-align: center;
  color: #ffffff;
}

.banner h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.banner p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}





/* ISO Certification Section Styles */
/* ISO Certification Section Styles */
.iso-certification {
  background: linear-gradient(135deg, #6e8efb, #a777e3, #fc466b);
  color: #fff;
  padding: 60px 30px;
  text-align: center;
  border-radius: 12px;
  margin: 30px auto;
  animation: fadeIn 1.5s ease-in-out;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

/* Animated Background Effect */
.iso-certification::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 20%, transparent 60%);
  animation: floatingBackground 10s infinite linear;
  z-index: 0;
  opacity: 0.6;
}

.iso-certification h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  background: -webkit-linear-gradient(#fff, #e5e7eb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: slideInFromTop 1s ease-in-out;
  z-index: 1;
  position: relative;
}

.iso-certification p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.8;
  font-weight: 400;
  animation: fadeIn 1.5s ease-in-out;
  z-index: 1;
  position: relative;
}

.iso-certification ul {
  list-style: none;
  padding: 0;
  margin: 25px 0;
  animation: fadeInUp 1.2s ease-in-out;
  z-index: 1;
  position: relative;
}

.iso-certification ul li {
  font-size: 1.2rem;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.iso-certification ul li::before {
  content: '✅';
  color: #facc15;
  margin-right: 10px;
  font-size: 1.5rem;
  animation: popIn 0.8s ease-in-out;
}

.iso-certification p:last-child {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 25px;
  animation: fadeIn 1.5s ease-in-out;
  z-index: 1;
  position: relative;
}

/* Hover Effects */
.iso-certification:hover {
  transform: scale(1.02);
  transition: all 0.4s ease-in-out;
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes floatingBackground {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .iso-certification {
    padding: 40px 20px;
  }

  .iso-certification h2 {
    font-size: 2rem;
  }

  .iso-certification p {
    font-size: 1rem;
  }

  .iso-certification ul li {
    font-size: 1rem;
  }
}




/* General Stay Updated Section */


/* Latest News Section */
.latest-news {
  margin-bottom: 40px;
  text-align: center;
  position: relative;
}

.latest-news h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #1e40af;
  text-transform: uppercase;
  animation: fadeIn 1.5s ease-in-out;
}

/* News Container with Auto-Scroll */
.news-container {
  max-height: 400px;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  animation: fadeInUp 1.2s ease-in-out;
  position: relative;
}

.news-container:hover {
  animation-play-state: paused;
}

/* Auto-Scrolling Animation */
@keyframes autoScroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.news-container .news-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: autoScroll 15s linear infinite;
}

.news-container:hover .news-wrapper {
  animation-play-state: paused;
}

/* News Item Styles */
.news-item {
  padding: 15px;
  border-radius: 6px;
  background: #f1f5f9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.news-item:hover {
  background: #e0f2fe;
  transform: translateY(-5px);
}

.news-item h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #2563eb;
}

.news-item p {
  font-size: 0.95rem;
  color: #475569;
  margin-bottom: 5px;
}

.news-item small {
  font-size: 0.85rem;
  color: #94a3b8;
}

/* Error and Loading State */
.error-message {
  color: #dc2626;
  font-size: 1rem;
  margin-top: 10px;
}

.latest-news p {
  font-size: 1rem;
  margin-top: 15px;
  color: #475569;
}

/* Load More Button */
.load-more-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background: #3b82f6;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.load-more-button:hover {
  background: #2563eb;
}

.latest-news p:last-child {
  font-size: 1rem;
  color: #64748b;
  margin-top: 15px;
}

/* FAQs Section */


.faqs h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #1e40af;
  margin-bottom: 20px;
  text-transform: uppercase;
  animation: fadeIn 1.5s ease-in-out;
}

/* FAQ Item */
.faq-item {
  margin: 10px auto;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #dbeafe;
  background: #f9fafb;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: left;
  max-width: 800px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.faq-item:hover {
  border-color: #3b82f6;
  background: #eff6ff;
  transform: translateY(-3px);
}

.faq-item h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 5px;
}

.faq-item p {
  font-size: 1rem;
  color: #475569;
  margin-top: 10px;
  line-height: 1.5;
  display: none;
}

.faq-item.expanded p {
  display: block;
  animation: fadeInUp 0.5s ease-in-out;
}



  
  .faqs h3 {
    font-size: 1.8rem;
  }

  .latest-news .news-item h4 {
    font-size: 1rem;
  }

  .latest-news .news-item p,
  .latest-news .news-item small {
    font-size: 0.9rem;
  }

  .faq-item h4 {
    font-size: 1rem;
  }

  .faq-item p {
    font-size: 0.9rem;
  }

  /* Desktop view (default) */
.stay-updated {
  display: flex;
  gap: 20px;
  padding: 40px 20px;
  background: #f9f9f9;
  justify-content: space-between;
}

/* Mobile view: Remove flex layout */
@media (max-width: 768px) {
  .stay-updated {
    display: block;
    /* Optionally adjust gap or other properties if needed */
    gap: 0;
  }
}


/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.news-feed-container {
  max-height: 400px;
  overflow-y: auto;
  scroll-behavior: smooth;
  border: 1px solid #ddd;
  padding: 10px;
  background: #f9f9f9;
}

.news-item {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.news-title {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.news-description {
  margin: 5px 0;
  color: #555;
}

.news-date {
  font-size: 0.8em;
  color: #999;
}
