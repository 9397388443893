/* ========== Team Section Base Styles ========== */
.team {
  background: linear-gradient(135deg, #f9fafc 0%, #e9ecf2 100%);
  padding: 80px 20px;
  text-align: center;
}

.team-content-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Title */
.section-title {
  font-size: 2.5rem;
  color: #0073e6;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Section Subtitle */
.section-subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 40px;
  line-height: 1.6;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

/* ========== Role Heading for Each Group ========== */
.role-heading {
  font-size: 1.8rem;
  color: #333;
  margin: 40px 0 20px;
  font-weight: bold;
  border-bottom: 2px solid #0073e6;
  display: inline-block;
  padding-bottom: 5px;
}

/* ========== Team Members Grid ========== */
.team-members {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  justify-items: center;
  align-items: start;
}

/* ========== Individual Team Member Card ========== */
.team-member {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 30px; /* Slight spacing below */
}

.team-member:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Image Container */
.team-image-container {
  width: 120px;
  height: 120px;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #0073e6;
  position: relative;
  flex-shrink: 0;
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Zoom Icon */
.zoom-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 6px;
  font-size: 1rem;
}

/* ========== Overlay Loader for Images ========== */
.image-loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Spinner for each image */
.image-spinner {
  width: 25px;
  height: 25px;
  border: 3px solid #cccccc;
  border-top: 3px solid #0073e6;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

/* Team Info */
.team-info {
  padding: 20px;
  text-align: center;
}

.team-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 6px;
  margin-top: 15px;
}

.team-role {
  font-size: 1rem;
  color: #0073e6;
  margin-bottom: 10px;
  font-weight: 500;
}

.team-qualification {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
  margin-bottom: 15px;
}

/* Social Links */
.social-links {
  display: inline-flex;
  gap: 10px;
  margin-top: 8px;
}

/* Social Icon Buttons */
.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0073e6;
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.social-links a:hover {
  background-color: #005bb5;
}

/* ========== GLOBAL LOADER ========== */
.global-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px; /* Adjust as needed */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc; /* Light grey */
  border-top: 5px solid #0073e6; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* ========== Spinner Animation ========== */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* ========== Responsive Design ========== */
@media (max-width: 768px) {
  .team-members {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .team-members {
    grid-template-columns: 1fr;
  }

  .team-member {
    max-width: 90%;
  }
}

/* END OF CSS */
