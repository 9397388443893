/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  
  color: #333;
}

.homepage-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Animations */
.fade-in-animation {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Top Bar */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: rgb(242, 76, 76);
  padding: 10px 5px;
}

.user-info {
 /* display: flex; */
  align-items: center;
  gap: 10px;
  margin-left: 27%;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s ease;
}

.logout-btn:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Layout */
.layout {
  display: flex;
  flex: 1;
}

/* Sidebar */
.sidebar {
  background-color: #276ca8;
  color: black;
  width: 250px;
  transition: width 0.3s ease;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed {
  width: 40%;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #495057;
}

.menu-options {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-options li {
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
}

.menu-options li:hover {
  background-color: #007bff;
  transform: translateX(10px);
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: auto;
}

.top-bar .back-btn {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  padding: 2px 6px; 
  margin-right: 10px;
  cursor: pointer;
 /* display: flex; */
  align-items: center;
  gap: 5px;
 /* border-radius: 4px;*/
}

.top-bar .back-btn:hover {
  background-color: #e0e0e0;
}







/* Loader container centered using fixed positioning */
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Spinner styling */
.spinner {
  width: 60px;
  height: 60px;
  border: 8px solid rgba(208, 50, 87, 0.2);
  border-top-color: #d03257;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

