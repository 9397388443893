/* ----------------------------
   Container & Title
----------------------------- */
.course-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background: linear-gradient(135deg, #f4f7fc 50%, #ffffff 50%);
  border-radius: 15px;
}

.course-title {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #d03257;
}

/* ----------------------------
   Top container for search + news
----------------------------- */
.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .top-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* ----------------------------
   Search Bar
----------------------------- */
.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-bar input {
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  width: 240px;
}

.search-bar input:focus {
  border-color: #007bff;
  outline: none;
}

.icon {
  font-size: 1.2rem;
  color: #666;
}

/* ----------------------------
   Course List
----------------------------- */
.course-list {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin-top: 20px;
}

/* Each Card ~30% */
.course-card {
  flex: 0 0 30%;
  min-width: 300px;
  max-width: 360px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
}

.course-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
}

@media (max-width: 480px) {
  .course-card {
    flex: 0 0 90%;
  }
}

/* ----------------------------
   Image Container
----------------------------- */
.image-container {
  position: relative;
  width: 100%;
  height: 200px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.course-image:hover {
  transform: scale(1.05);
}

.no-image {
  color: #999;
  font-size: 14px;
}

/* Card image loader in center */
.image-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ----------------------------
   Card Content
----------------------------- */
.course-card h3 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #d03257;
  text-align: center;
  margin: 15px 0 10px 0;
}

.course-description {
  font-size: 0.9rem;
  color: #666;
  margin: 0 15px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  flex: 1;
}

/* Collapsed vs. Expanded descriptions */
.course-description.collapsed {
  max-height: 60px;
}
.course-description.expanded {
  max-height: none;
}

/* ----------------------------
   Read Toggle
----------------------------- */
.read-toggle {
  display: inline-block;
  margin: 5px 15px 15px 15px;
  padding: 5px 12px;
  background-color: #f0f8ff;
  color: #007bff;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
}

.read-toggle:hover {
  background-color: #d0eaff;
  transform: scale(1.05);
}

/* ----------------------------
   Buttons
----------------------------- */
.action-btn {
  font-family: inherit;
  cursor: pointer;
  outline: none;
  border: none;
}

.apply-btn {
  width: 60%;
  margin: 10px auto 15px auto;
  background: linear-gradient(135deg, #d03257, #ff4b2b);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  transition: transform 0.3s ease, background 0.3s ease;
  text-align: center;
}

.apply-btn:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
}

/* ----------------------------
   Loader (Spinner)
----------------------------- */
.loader {
  width: 40px;
  height: 40px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #d03257;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Full-screen Page Loader Container (centered) */
.page-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999; /* in front of everything */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------------------------
   Modal Overlay & Content
----------------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Semi-opaque black background (dark overlay) */
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* allow scrolling if modal is taller than viewport */
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 650px;
  margin: 40px auto;
  /* Light background or subtle gradient */
  background: linear-gradient(135deg, #ffffff 0%, #fdfbfb 100%);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
  max-height: 80vh; /* ensures long content scrolls inside */
  overflow-y: auto;
  animation: popIn 0.4s ease-out;
  box-sizing: border-box; /* ensure padding is included */
}

/* Modal Intro Animation */
@keyframes popIn {
  from {
    transform: scale(0.8);
    opacity: 0.6;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Modal Title & Close Button */
.modal-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #d03257;
  margin-bottom: 15px;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #ff4b2b;
  border: none;
  border-radius: 50%;
  padding: 8px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.close-btn:hover {
  background: #ff416c;
  transform: scale(1.1);
}

/* Modal Description (with read more/less) */
.modal-description {
  font-size: 1rem;
  color: #444;
  line-height: 1.6;
  margin-bottom: 20px;
  transition: max-height 0.3s ease;
  overflow: hidden; /* hide extra text unless expanded */
}

/* Collapsed vs. Expanded in modal */
.modal-description.collapsed {
  max-height: 100px;
}
.modal-description.expanded {
  max-height: none;
}

/* Additional read toggle for the modal */
.modal-read-toggle {
  display: inline-block;
  color: #007bff;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 6px 10px;
  background: #f0f8ff;
  border-radius: 4px;
  transition: background 0.3s, transform 0.2s;
}

.modal-read-toggle:hover {
  background: #dceeff;
  transform: scale(1.05);
}

/* Modal Actions (buttons, share, zoom, etc.) */
.modal-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.modal-actions .apply-btn {
  width: auto; /* override if needed */
}

/* Share Buttons */
.share-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.share-btn {
  background: #3498db;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.share-btn:hover {
  transform: scale(1.1);
  background: #2980b9;
}

/* Zoom Controls */
.zoom-controls {
  display: flex;
  gap: 6px;
}

.zoom-controls button {
  background: #f0f0f0;
  color: #333;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.zoom-controls button:hover {
  background: #ddd;
}

/* Force Quill bubble theme to have white BG, black text for readability */
.text-zoom-container .ql-bubble .ql-editor {
  background-color: #fff !important;
  color: #000 !important;
}

/* Zoom container transitions */
.text-zoom-container {
  transition: transform 0.3s ease;
}

