.news-feed-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .news-feed-container {
    max-height: 400px;
    overflow-y: auto;
    scroll-behavior: smooth;
    border: 1px solid #ddd;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 4px;
  }
  
  .news-item {
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    transition: transform 0.3s ease-in-out;
  }
  
  .news-item:last-child {
    border-bottom: none;
  }
  
  .news-title {
    margin: 0 0 5px;
    font-size: 1.2em;
    color: #333;
  }
  
  .news-description {
    margin: 5px 0;
    color: #555;
    line-height: 1.4;
  }
  
  .news-date {
    font-size: 0.8em;
    color: #999;
  }
  
  .loading-text {
    text-align: center;
    color: #555;
    margin-top: 10px;
  }
  
  /* ✅ Fix scrolling issues */
  .news-feed-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .news-feed-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  .news-feed-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* ✅ Mobile View Fix */
  @media (max-width: 768px) {
    .news-feed-container {
      padding: 5px;
    }
  }
  