/* Root variables for consistency */
:root {
  --primary-color: #1d3557;
  --secondary-color: #457b9d;
  --accent-color: #f4a261;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --text-color: #333;
  --bg-light: #f9f9f9;
  --bg-white: #fff;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  --transition-speed: 0.3s;
}

/* Main Container */
.job-services-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  padding: 20px 0;
}

.job-services-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: bold;
}

/* Actions Section */
.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

/* Search Container */
.search-container {
  display: flex;
  align-items: center;
  background: var(--bg-white);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.search-container input {
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  width: 200px;
}

.search-button {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease;
}

.search-button:hover {
  background-color: var(--accent-color);
}

/* Page Size Selector */
.page-size-selector {
  display: flex;
  align-items: center;
  gap: 5px;
}

.page-size-label {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
}

.page-size-dropdown {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Add Button */
.add-button {
  background-color: var(--success-color);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color var(--transition-speed) ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-button:hover {
  background-color: #218838; /* Darker green */
}

/* Form Container */
.form-container {
  background-color: var(--bg-white);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: var(--box-shadow);
  animation: fadeIn var(--transition-speed) ease;
}

.form-container input,
.form-container textarea,
.form-container select {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

/* Error Message */
.validation-error {
  color: var(--danger-color);
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: 500;
}

/* Form Buttons Row */
.form-buttons-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Submit Button */
.form-submit-button {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color var(--transition-speed) ease;
}

.form-submit-button:hover {
  background-color: var(--accent-color);
}

/* Cancel Button */
.cancel-button {
  background-color: #f8f9fa;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
}

.cancel-button:hover {
  background-color: var(--primary-color);
  color: #fff;
}

/* Table Container */
.table-container {
  width: 100%;
  overflow-x: auto; /* For responsive horizontal scroll if needed */
  box-shadow: var(--box-shadow);
  background: var(--bg-white);
  border-radius: 10px;
}

/* Jobs Table */
table {
  width: 100%;
  border-collapse: collapse;
  min-width: 650px; /* so it can scroll horizontally on small screens if needed */
  border-radius: 10px;
  overflow: hidden;
}

table thead {
  background-color: var(--secondary-color);
  color: #fff;
}

table th,
table td {
  text-align: left;
  padding: 12px;
  font-size: 15px;
  border-bottom: 1px solid #ddd;
}

table tr:hover {
  background-color: #f1f1f1;
}

table th {
  font-weight: 600;
}

/* Edit & Delete Buttons */
.edit-button,
.delete-button {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  padding: 7px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color var(--transition-speed) ease;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: var(--accent-color);
}

.delete-button {
  background-color: var(--danger-color);
}

.delete-button:hover {
  background-color: #c82333;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
}

.pagination button {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: var(--accent-color);
}

.pagination span {
  font-size: 16px;
}

/* Keyframe for fade in */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to   { opacity: 1; transform: translateY(0); }
}

/* Responsive */
@media (max-width: 768px) {
  .actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .search-container input {
    width: 150px;
  }
  
  .page-size-selector {
    align-self: flex-start;
  }
  
  .add-button {
    align-self: flex-start;
  }
}

@media (max-width: 480px) {
  .search-container input {
    width: 120px;
  }
  
  .table-container {
    border-radius: 5px;
  }
  
  table {
    min-width: 500px;
  }
  
  .edit-button,
  .delete-button {
    font-size: 12px;
    padding: 5px 8px;
  }
}
