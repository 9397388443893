/* The news-section uses sticky positioning so that it stays at the top right,
   yet scrolls with the page when the container is scrolled. */
   .news-section {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 20px;      /* Offset from top */
    margin-left: auto; /* Push to the right */
    width: 300px;
    max-width: 90%;
    background: #fdf1c4;  /* Soft yellow background */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border: 3px solid #ff9800;
    font-family: Arial, sans-serif;
    /* Ensure it stays in the normal page flow so it scrolls with content */
  }
  
  /* Title styling */
  .news-title {
    font-size: 22px;
    font-weight: bold;
    color: #d84315;  /* Bold reddish-orange */
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Loading text styling */
  .loading-text {
    text-align: center;
    font-size: 16px;
    color: #666;
    padding: 20px;
  }
  
  /* News container with fixed height and hidden scrollbar */
  .news-container {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    border-radius: 8px;
    background: #ffffff;
    padding: 10px;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.15);
    position: relative;
  }
  
  /* Hide scrollbar for WebKit browsers */
  .news-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Layout for the news list */
  .news-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px;
  }
  
  /* Styling for each news item */
  .news-item {
    background: #fff8e1;  /* Light yellow paper effect */
    border-left: 4px solid #ff9800;
    border-radius: 5px;
    padding: 10px;
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .news-item:hover {
    transform: scale(1.02);
    background: rgba(255, 193, 7, 0.2);
  }
  
  /* News heading styles */
  .news-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Alternating title colors */
  .red-title {
    color: red;
  }
  
  .blue-title {
    color: blue;
  }
  
  /* News content styling */
  .news-content {
    font-size: 14px;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .news-section {
      position: relative;
      width: 100%;
      max-width: none;
      margin-top: 20px;
      right: 0;
    }
    .news-container {
      height: 250px;
    }
    .news-item {
      font-size: 13px;
      padding: 8px;
    }
  }
  